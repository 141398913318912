import React from "react";
import { styled } from "styled-components";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import LogoIcon from "../../Components/Svgicons/LogoIcon";
import StyledText from "../../Components/StyledComponents/StyledText";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import FormikInputField from "../../Components/Formik/FormikInputField";
import { useUserLoginMutation } from "../../Services/loginApi/loginApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../Services/Auth/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const UserLogin = () => {
  const [login, { isLoading }] = useUserLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (values) => {
    const userData = {
      email: values.email,
      personalNumber: values?.personalNumber,
    };

    login(userData)
      .then((data) => {
        if (data?.data?.token) {
          dispatch(
            setCredentials({
              token: { accessToken: data?.data.token },
              account: data?.data.userInfo,
              userType: data?.data?.userInfo?.userType,
            })
          );
          navigate("/resources");
        }
        else {
          toast.error(data?.error?.data?.message)

        }
      })
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    personalNumber: Yup.string().required("Personal Number is required"),
  });

  return (
    <StyledContainer
      className={`col-10 col-sm-8 col-lg-7 d-flex flex-column align-items-center p-3 p-sm-5 bg-white rounded-3`}
    >
      <div className="d-flex flex-column align-items-center w-100">
        <LogoContainer className="w-100 pb-5">
          <LogoIcon />
        </LogoContainer>

        <StyledText $fontSize="18px" $fontWeight={600}>User Login</StyledText>
        <Formik
          initialValues={{
            email: '',
            personalNumber: ''
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="w-100 pt-4">
            <StyledText $fontSize="16px" $fontWeight={600}>
              Email *
            </StyledText>
            <div className="pb-2" />
            <FormikInputField
              type="text"
              label="Email"
              name="email"
              placeholder="Enter your email address"
            />

            <div className="mt-4">
              <StyledText $fontSize="16px" $fontWeight={600}>
                Personal Number *
              </StyledText>
              <div className="pb-2" />
              <FormikInputField
                type="text"
                label="Personal Number"
                name="personalNumber"
                placeholder="Enter your personal number"
              />
            </div>
            <div className=" d-flex align-items-center justify-content-center pt-5 text-center">
              <StyledButton
                type="submit"
                className="btn btn-primary text-white ms-lg-2"
                loading={isLoading}
                disabled={isLoading}
              >
                Submit
              </StyledButton>
            </div>
          </Form>
        </Formik>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  .loader {
    height: 50px;
    width: 50px;
  }
`;



const LogoContainer = styled.div`
  text-align: center;
  @media screen and (max-width: 575px) {
    text-align: start;
  }
`;
export default UserLogin;
